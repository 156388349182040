import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider, Typography } from "@mui/material";
import { SocketConnectionContext, useCommandListener } from "Components/SocketConnection";
import { useCallback, useContext, useMemo, useState } from "react";

interface IProps {
    open: boolean;
    onClose: () => void;
    petId: string;
    pishockIndex: number;
}
export const VoiceShockerSettingsModal = (props: IProps) => {
    const [currentMicLevel, setCurrentMicLevel] = useState<number>(0);
    const [micThreshold, setMicThreshold] = useState<number>(0);
    const con = useContext(SocketConnectionContext);

    useCommandListener("WRITE-MIC-LEVEL", (data: any) => {
        if (data.pet !== props.petId) {
            return;
        }

        setCurrentMicLevel(data.level.replace(",", ".") as number);
    }, []);

    const save = useCallback(() => {
        con?.send("WRITE-MIC-THRESHOLD", {
            pet: props.petId,
            pishockIndex: props.pishockIndex,
            threshold: micThreshold,
        });
        props.onClose();
    }, [con, micThreshold]);

    const shockStatus = useMemo(() => {
        if (micThreshold < 0.01) {
            return <Typography variant="h6" color="gray">Inactive</Typography>;
        }
        if (currentMicLevel > micThreshold) {
            return <Typography variant="h6" color="error">bzzz</Typography>;
        } else {
            return <Typography variant="h6" color="success">All good. Pet is quiet :)</Typography>;
        }
    }, [currentMicLevel, micThreshold]);

    const currentMicLevelPercent = Math.round(currentMicLevel * 100);

    return <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth="md"
    >
        <Box>
            <DialogTitle id="alert-dialog-title">
                Voice Shocker
            </DialogTitle>
            <DialogContent>
                <Slider min={0} max={1} value={micThreshold}
                    step={0.01} onChange={(_, val) => { setMicThreshold(val as number) }}
                    sx={{
                        '& .MuiSlider-rail': {
                            background: `linear-gradient(to right, red ${currentMicLevelPercent}%, gray ${currentMicLevelPercent}%, gray ${100 - currentMicLevelPercent}%)`
                        },
                    }} />
                {shockStatus}
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
                <Button onClick={save}>Save</Button>
            </DialogActions>
        </Box>
    </Dialog>
}