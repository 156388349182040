import { Box, Button, Slider } from "@mui/material";
import { SocketConnectionContext } from "Components/SocketConnection";
import { useCallback, useContext, useState } from "react";
import BoltIcon from '@mui/icons-material/Bolt';
import VibrationIcon from '@mui/icons-material/Vibration';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import SpeedIcon from '@mui/icons-material/Speed';


type PiShockActionType = 'shock' | 'vibrate';
const MAX_DURATION = 13;


interface IProps {
    petId: string;
    action: PiShockActionType;
    pishockIndex: number;
}
export const PiShockAction = (props: IProps) => {
    const con = useContext(SocketConnectionContext);
    const [duration, setDuration] = useState<number>(1);
    const [hoverDuration, setHoverDuration] = useState<number>(0);
    const [intensity, setIntensity] = useState<number>(1);

    const convertDurationToSeconds = (duration: number) => {
        if (duration <= 0 || duration > MAX_DURATION) {
            return 0;
        }
        switch (duration) {
            case 1:
                return 0.1;
            case 2:
                return 0.2;
            case 3:
                return 0.3;
            default:
                return duration - 3;
        }
    }

    const valueToColor = (value: number, min: number, max: number): string => {
        value = Math.max(min, Math.min(max, value));
        const midPoint = (min + max) / 2;

        if (value <= midPoint) {
            // Transition from Gray (128, 128, 128) to Orange (255, 165, 0)
            const factor = (value - min) / (midPoint - min); // Normalize between 0 and 1 for the first half
            const r = Math.round(128 + factor * (255 - 128)); // Red channel from Gray to Orange
            const g = Math.round(128 + factor * (165 - 128)); // Green channel from Gray to Orange
            const b = Math.round(128 - factor * 128);         // Blue channel from Gray to Orange
            return `rgb(${r}, ${g}, ${b})`;
        } else {
            // Transition from Orange (255, 165, 0) to Red (255, 0, 0)
            const factor = (value - midPoint) / (max - midPoint); // Normalize between 0 and 1 for the second half
            const r = 255;                                        // Red stays 255
            const g = Math.round(165 - factor * 165);             // Green decreases from 165 (orange) to 0 (red)
            const b = 0;                                          // Blue stays 0
            return `rgb(${r}, ${g}, ${b})`;
        }
    }

    const command = useCallback(
        (index: number, action: PiShockActionType) => {
            con?.send("WRITE-PISHOCK-ZAP", {
                pet: props.petId,
                index: index,
                mode: action,
                intensity: intensity,
                duration: convertDurationToSeconds(duration) * 1000,
            });
        },
        [con, props.petId, duration, props.action, intensity]
    );

    return <>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexShrink: 0, mr: 4 }}>
                {props.action === 'shock' ? <BoltIcon sx={{ fontSize: "3em" }} /> : <VibrationIcon sx={{ fontSize: "3em" }} />}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ mr: 2, flexGrow: 1 }}>
                        <Slider
                            value={duration}
                            min={1}
                            max={13}
                            marks
                            sx={{
                                color: valueToColor(duration, 1, 13),
                                '& .MuiSlider-thumb': {
                                    backgroundColor: valueToColor(duration, 1, 13),
                                },
                                '& .MuiSlider-track': {
                                    backgroundColor: valueToColor(duration, 1, 13),
                                },
                            }}
                            onChange={(e, val) => {
                                const number = val as number;
                                if (number > MAX_DURATION) {
                                    return;
                                }
                                if (number <= 0) {
                                    return;
                                }
                                setDuration(val as number);
                            }}
                            valueLabelFormat={val => `${convertDurationToSeconds(duration)} s`}
                            valueLabelDisplay="auto"
                        />
                    </Box>
                    <Box sx={{ width: 70, flexShrink: 0, display: 'flex', alignItems: 'center', color: valueToColor(duration, 1, 13) }}>
                        <AccessAlarmIcon sx={{ mr: 1 }} /> {convertDurationToSeconds(hoverDuration > 0 ? hoverDuration : duration)} s
                    </Box>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box sx={{ mr: 2, flexGrow: 1 }}>
                        <Slider
                            value={intensity}
                            sx={{
                                color: valueToColor(intensity, 1, 100),
                                '& .MuiSlider-thumb': {
                                    backgroundColor: valueToColor(intensity, 1, 100),
                                },
                                '& .MuiSlider-track': {
                                    backgroundColor: valueToColor(intensity, 1, 100),
                                },
                            }}
                            min={1}
                            max={100}
                            onChange={(e, val) => setIntensity(val as number)}
                        />
                    </Box>
                    <Box sx={{ width: 70, flexShrink: 0, display: 'flex', alignItems: 'center', color: valueToColor(intensity, 1, 100) }}>
                        <SpeedIcon sx={{ mr: 1 }} /> {intensity}
                    </Box>
                </div>
            </Box>

            <Box sx={{ ml: 2 }}>
                <Button onClick={() => command(props.pishockIndex, 'vibrate')} sx={{ width: "100%" }}>
                    Beep
                </Button>
                <Button onClick={() => command(props.pishockIndex, props.action)} sx={{ width: "100%" }}>
                    {props.action === 'shock' ? 'Shock' : 'Vibrate'}
                </Button>
            </Box>
        </Box>
    </>
};