import {
	Alert,
	Button,
} from "@mui/material";
import {
	PiShockAction,
	Preloader,
	SocketConnectionContext,
	useCommandListener,
	VoiceShockerSettingsModal,
} from "components";
import { useContext, useEffect, useState } from "react";

interface IPiShock {
	index: number;
	name: string;
}
interface IProps {
	petId: string;
}
export const PiShockList = (props: IProps) => {
	const con = useContext(SocketConnectionContext);
	const [pishocks, setPishock] = useState<IPiShock[]>();
	const [voiceShockerOpen, setVoiceShockerOpen] = useState(false);

	useEffect(() => {
		con?.send("READ-PISHOCKS", {
			pet: props.petId,
		});
	}, [con, props.petId]);

	useCommandListener(
		"WRITE-PISHOCK",
		(data: any) => {
			if (data.pet !== props.petId) {
				return;
			}

			setPishock((c) => {
				if (c === undefined) {
					return [{ index: data.index, name: data.name }];
				}

				if (c.find((x) => x.index === data.index) !== undefined) {
					return c;
				}

				return [...c, { index: data.index, name: data.name }];
			});
		},
		[props.petId]
	);

	if (pishocks === undefined) {
		return <Preloader />;
	}

	if (pishocks.length === 0) {
		return <Alert severity="info">Das Pet hat keine Pishocks</Alert>;
	}

	return (
		<>
			{pishocks.map((p) => (
				<div>
					{p.name}
					<Button onClick={() => setVoiceShockerOpen(true)}>Voice Shocker</Button>
					<PiShockAction petId={props.petId} pishockIndex={p.index} action="vibrate" />
					<PiShockAction petId={props.petId} pishockIndex={p.index} action="shock" />
				</div>
			))}
			{pishocks.map((p) => (
				<VoiceShockerSettingsModal
					open={voiceShockerOpen}
					onClose={() => setVoiceShockerOpen(false)}
					petId={props.petId}
					pishockIndex={p.index} />
			))}
		</>
	);
};
